/*
Das ist eine gute Idee allerdings bekomme ich sie nicht
via background-image: url('@{assetsPath}/images/icon--rightArrow.svg');
zum laufen

@paths : {
assets: "@/../../assets";
images: "@/../../assets/images";
}
*/
.background--grey {
  background-color: #2B2E34;
}
.background--blue {
  background-color: #0B2CC2;
}
.background--lighterBlue {
  background-color: #1478C2;
}
.background--gold {
  background-color: #C47806;
}
.background--lightGold {
  background-color: #C4950B;
}
.background--pink {
  background-color: #DD2C6C;
}
.background--white {
  background-color: #ffffff;
}
.color--grey {
  color: #2B2E34;
}
.color--blue {
  color: #0B2CC2;
}
.color--lighterBlue {
  color: #1478C2;
}
.color--gold {
  color: #C47806;
}
.color--lightGold {
  color: #C4950B;
}
.color--pink {
  color: #DD2C6C;
}
.color--white {
  color: #ffffff;
}
@media (min-width: 1023px ) {
}@media (max-width: 1023px ) {
}.AbEvents__nav {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #0B2CC2;
}
@media (min-width: 1023px ) {
  .AbEvents__nav {
    height: 72px;
  }
}
@media (max-width: 1023px ) {
  .AbEvents__nav {
    height: 72px;
  }
}
@media (max-width: 750px ) {
  .AbEvents__nav {
    height: 40px;
  }
}
.AbEvents__navFilter {
  overflow: hidden;
}
.AbEvents__navFilterBtn {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  outline: none;
  text-align: inherit;
  cursor: pointer;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  padding: 0.35em 0.65em;
}
.AbEvents__navFilterBtn::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.AbEvents__navFilterBtn::after {
  display: inline-flex;
  padding-left: 0.35em;
  transform: scale(1.45);
  color: white;
}
.AbEvents__navFilterBtn--isActive {
  color: white;
  background-color: #1478C2;
}
.AbEvents__groupTitle {
  display: grid;
  padding-top: 1.45em;
  padding-bottom: 0.85em;
  color: #BFCCCC;
}
[showBorders3] .AbEvents__groupTitle {
  outline: 1px solid red;
}
[showBorders3] .AbEvents__groupTitle > * {
  outline: 1px dotted red;
}
.AbEvents__groupTitle > * {
  min-height: 0;
  min-width: 0;
}
@media (min-width: 1023px ) {
  .AbEvents__groupTitle {
    grid-column-gap: 24px;
  }
}
@media (max-width: 1023px ) {
  .AbEvents__groupTitle {
    grid-column-gap: 20px;
  }
}
@media (max-width: 750px ) {
  .AbEvents__groupTitle {
    grid-column-gap: 10px;
  }
}
.AbEvents__groupTitle > *:nth-child(1) {
  grid-area: a;
}
.AbEvents__groupTitle > *:nth-child(2) {
  grid-area: b;
}
.AbEvents__groupTitle > *:nth-child(3) {
  grid-area: c;
}
.AbEvents__groupTitle > *:nth-child(4) {
  grid-area: d;
}
.AbEvents__groupTitle > *:nth-child(5) {
  grid-area: e;
}
.AbEvents__groupTitle > *:nth-child(6) {
  grid-area: f;
}
.AbEvents__groupTitle > *:nth-child(7) {
  grid-area: g;
}
.AbEvents__groupTitle > *:nth-child(8) {
  grid-area: h;
}
.AbEvents__groupTitle > *:nth-child(9) {
  grid-area: i;
}
.AbEvents__groupTitle > *:nth-child(10) {
  grid-area: j;
}
@media (min-width: 1023px ) {
  .AbEvents__groupTitle {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-areas: ". . . a a a a a a a . .";
  }
}
@media (max-width: 1023px ) {
  .AbEvents__groupTitle {
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas: ". . a a a a a";
  }
}
@media (max-width: 750px ) {
  .AbEvents__groupTitle {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "a a a a";
  }
}
